<template>
  <div>
    <div class="news">
      <div class="title">
        GV20 Therapeutics to Present at the Needham 23<sup>rd</sup> Annual Virtual Healthcare Conference
      </div>
      <div class="time">
        Apr 2, 2024
      </div>
      <div class="content">
        
        <p>
          Cambridge, Massachusetts – April 2, 2024 – GV20 Therapeutics, a clinical-stage biotechnology company integrating AI, genomics, and disease biology to create next-generation antibody therapeutics for cancer, announced today that it will present at the Needham 23rd Annual Virtual Healthcare Conference, taking place April 8-11, 2024.
        </p>
        <p>
          Company management will also be conducting one-on-one meetings with investors at the conference.
        </p>
        <p>
          <div class="font-weight-bold">
            About GV20 Therapeutics
          </div>
          At GV20 Therapeutics, our mission is to develop a differentiated pipeline of innovative antibody therapeutics to transform cancer treatment.  We leverage our in-house STEAD platform (Simultaneous Target Evaluation and Antibody Discovery), which integrates AI, genomics, and disease biology to identify novel drug targets and create next-generation therapies against these targets.  Our lead program, GV20-0251, a first-in-class, fully human, Fc-attenuated IgG1 monoclonal antibody targeting a novel innate immune checkpoint IGSF8, is in a multi-center Phase I clinical trial in patients with advanced solid tumors (<a style="color:#2d8cf0;" href="https://classic.clinicaltrials.gov/ct2/show/NCT05669430">NCT05669430</a>). GV20 is headquartered in Cambridge, MA.
        </p>
        <p>
          To learn more about GV20, please visit <a style="color:#2d8cf0;" href="https://gv20tx.com/">https://gv20tx.com/</a> and follow the company on LinkedIn.
        </p>

        <p>
          <div class="font-weight-bold">
            Business Contact
          </div>
          Ying Gong, Ph.D., CBO<br>
          BD@gv20tx.com
        </p>
        <p>
          <div class="font-weight-bold">
            Investor and Media Contact
          </div>
          Amy Conrad<br>
          Juniper Point<br>
          amy@juniper-point.com
        </p>
      </div>
    </div>
    <Footers />
  </div>
</template>
<script>
import Footers from '@/components/Footers'
export default {
  data () {
    return {

    }
  },
  components: {
    Footers
  }
}
</script>
<style scoped lang="scss">
.news {
  font-size: 30px;
  font-weight: 500;
  padding: 0 40px;
  .title {
    font-weight: bold;
    font-size: 40px;
    padding-top: 40px;
  }
  .time {
    font-size: 20px;
    color: #666666;
    font-style: italic;
    margin: 50px 0;
  }
  .item {
    .font-weight-bold {
      margin-bottom: 30px;
    }
  }
}
</style>
